import { gql } from '@apollo/client';
import BaseService from './baseService';

class PhoneTypesService extends BaseService {
  constructor() {
    super();
    this.phoneTypeGql = `
      phoneTypeId
      phoneType
    `;
  }

  getPhoneTypes = () =>
    new Promise((resolve, reject) => {
      const PHONE_TYPES = gql`
      query {
        phoneTypes{
            ${this.phoneTypeGql}
        }
      }
    `;

      this.client
        .query({
          fetchPolicy: 'no-cache',
          query: PHONE_TYPES,
        })
        .then(result => {
          if (result.data.phoneTypes) {
            resolve(result.data.phoneTypes);
          } else {
            reject(result.data.error);
          }
        })
        .catch(error => {
          console.log('error', error);
          reject(error);
        });
    });
}

const phoneTypesService = new PhoneTypesService(
  `${process.env.REACT_APP_DISPATCH_QORE_API}/graphql`
);

export default phoneTypesService;
