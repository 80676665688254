export default {
  'actions': 'Actions',
  'agentCodes': 'Agent Codes',
  'admin': 'Admin',
  'allRoles': 'All Roles',
  'avatar': 'Avatar',
  'name.firstName': 'First Name',
  'name.lastName': 'Last Name',
  'email': 'Email',
  'edit': 'Edit',
  'roles': 'Roles',
  'dateAdded': 'Date Added',
  'userRoles': 'User Roles',
  'sendEmail': 'Send Email',
  'resetPassword': 'Reset Password',
  'role': 'Role',
  'users': 'Users',
  'user': 'User',
  'search': 'Search',
  'searchUsers': 'Search users',
  'createdAt': 'Created At',
  'required': 'Required',
  'emailAddress': 'Email Address',
  'password': 'Password',
};