export default [
  {
    'serviceId': 94,
    'serviceName': 'Low Clearance Tow + Tow Store',
    'serviceType': 'LC+TS',
  },
  {
    'serviceId': 95,
    'serviceName': 'Flat Bed Towing + Tow Store',
    'serviceType': 'FB+TS',
  },
  {
    'serviceId': 96,
    'serviceName': 'Light Duty Recovery + Low Clearance Tow + Tow Store',
    'serviceType': 'LDR+LC+TS',
  },
];
