import { lazy } from 'react';
import { AuthGuard } from '@nsd/fe';
import { ReceiptOutlined as ReceiptIcon } from '@material-ui/icons';
import DashboardLayout from 'src/layouts/DashboardLayout';
import { appIcon } from '../appIcon';

const MODULES = {
  BILLING_INVOICES_DEPRECATED: 'invoices-ab226bef-68e5-4026-91f8-7e0f7f37a5a5',
  BILLING_INVOICES: 'invoices-c20efd6f-c54e-4d2a-b533-dd8e7d84aafb',
};

const appConfig = {
  appId: 'billing-ee010e41-0436-42f7-b5f3-316df5fe3c13',
  appName: 'billing.billing',
  priority: 2,
  path: 'billing',
  icon: appIcon.billingQoreApp,
  guard: AuthGuard,
  layout: DashboardLayout,
  routes: [
    {
      exact: true,
      path: 'invoices',
      view: lazy(() => import('src/views/BillingInvoicesDeprecatedView/index')),
    },
    {
      exact: true,
      path: 'billingInvoices',
      view: lazy(() => import('src/views/BillingInvoicesView/index')),
    },
  ],
  topBarConfig: {
    contacts: false,
    search: false,
    notifications: true,
    settings: true,
  },
  navConfig: {
    navBarItems: [
      {
        subheader: 'billing.billing',
        items: [
          {
            title: 'billing.invoices',
            icon: ReceiptIcon,
            href: 'invoices',
            module: MODULES.BILLING_INVOICES_DEPRECATED,
          },
          {
            title: 'billing.invoices',
            icon: ReceiptIcon,
            href: 'billingInvoices',
            module: MODULES.BILLING_INVOICES,
          },
        ],
      },
    ],
  },
};

export default appConfig;
