export default {
  confieChargedClients: 'Confie Charged Clients',
  nsdChargedConfie: 'NSD Charged Confie',
  applicationsReceivedByNSD: 'Applications Received By NSD',
  nsdChargedConfiePerApplicationAverage: 'NSD Charged Confie Per Application (Avg)',
  confieChargedClientsPerApplicationAverage: 'Confie Charged Clients Per Application (Avg)',
  confieProfit: 'Confie Profit',
  profit: 'Profit',
  totalProfit: 'Total Profit',
  totalProfitByState: 'Total Profit By State',
  searchProducts: 'Search Products',
  office: 'Office',
  district: 'District',
  product: 'Product',
};