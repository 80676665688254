import translations from './ClaimsView';

export default {
  amount: 'Amount',
  billing: 'Billing',
  'billingMonth': 'Billing Month',
  'billingPeriod': 'Billing Period',
  invoices: 'Invoices',
  'invoiceNumber': 'Invoice #',
  'status': translations.status,
  'document': 'Document',
  'allDocuments': 'All Documents',
  'cancellationStatement': 'Cancellation Statement',
  'claimInvoice': 'Claim Invoice',
  'printedCancellationCheck': 'Printed Cancellation Check',
};