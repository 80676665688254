import { gql } from '@apollo/client';
import BaseService from './baseService';

class SymptomsService extends BaseService {
  constructor(uri) {
    super(uri);
  }

  async getSymptoms() {
    try {
      const symptomQuery = gql`
        query Query {
          symptom {
            symptom
            symptomId
          }
        }
      `;

      const res = await this.client.query({
        fetchPolicy: 'no-cache',
        query: symptomQuery,
      });

      if (res.data.error) {
        const error = {
          message: 'Error getting symptoms',
          error: res.data.error,
        };
        throw error;
      }
      return res.data.symptom;
    } catch (e) {
      console.error('symptomService', e);
    }
  }
}

const symptomsService = new SymptomsService(
  `${process.env.REACT_APP_DISPATCH_QORE_API}/graphql`
);

export default symptomsService;
