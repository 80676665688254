import { NSD_STATIC_URL } from 'src/utils/blobStorage';

const storageUrl = `${NSD_STATIC_URL}/assets/Qore_Brand_Icons`;

export const appIcon = {
  adminQoreApp: `${storageUrl}/adminQoreAppIcon.png`,
  agentQoreApp: `${storageUrl}/agentQoreAppIcon.png`,
  billingQoreApp: `${storageUrl}/billingQoreAppIcon.png`,
  cashQoreApp: `${storageUrl}/cashQoreAppIcon.png`,
  claimsQoreApp: `${storageUrl}/claimsQoreAppIcon.png`,
  dataQoreApp: `${storageUrl}/dataQoreAppIcon.png`,
  dealerQoreApp: `${storageUrl}/dealerQoreAppIcon.png`,
  developeQoreApp: `${storageUrl}/developeQoreAppIcon.png`,
  dispatchQoreApp: `${storageUrl}/dispatchQoreAppIcon.png`,
  documents: `${storageUrl}/documentsQoreAppIcon.png`,
  insightQoreApp: `${storageUrl}/insightQoreAppIcon.png`,
  insuranceQoreApp: `${storageUrl}/insuranceQoreAppIcon.png`,
  ledgerQoreApp: `${storageUrl}/ledgerQoreAppIcon.png`,
  licenseQoreApp: `${storageUrl}/licenseQoreAppIcon.png`,
  marketQoreApp: `${storageUrl}/marketQoreAppIcon.png`,
  notificationsQoreApp: `${storageUrl}/notificationsQoreAppIcon.png`,
  pricebookQoreApp: `${storageUrl}/pricebookQoreAppIcon.png`,
  providerQoreApp: `${storageUrl}/providerQoreAppIcon.png`,
  reportsQoreApp: `${storageUrl}/insightQoreAppIcon.png`,
  salesQoreApp: `${storageUrl}/salesQoreAppIcon.png`,
  underwriterQoreApp: `${storageUrl}/underwriterQoreAppIcon.png`,
};
