import admin from './admin';
import dispatch from './dispatch';
import claim from './claims';
import billing from './billing';
import reports from './reports';
import documents from './documents';
import insurance from './insurance';

export default [
  admin,
  dispatch,
  claim,
  billing,
  reports,
  documents,
  insurance,
];
