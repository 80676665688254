import { combineReducers } from 'redux';
import { DEFAULT_REDUCERS } from '@nsd/fe';
import typesReducer from './typesReducer';

// Developer adds their own reducers before spreading
// in the DEFAULT_REDUCERS to not overwrite any needed keys
const rootReducer = combineReducers({
  ...DEFAULT_REDUCERS,
  types: typesReducer,
});

export default rootReducer;
