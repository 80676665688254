/* eslint-disable no-console */
import merge from 'lodash/merge';
import {
  responsiveFontSizes,
  createTheme as createMuiTheme,
} from '@material-ui/core/styles';
import typography from './typography';

const baseConfig = {
  direction: 'ltr',
  typography,
  overrides: {
    MuiLinearProgress: {
      root: {
        borderRadius: 3,
        overflow: 'hidden',
      },
    },
    MuiListItemIcon: {
      root: {
        minWidth: 32,
      },
    },
    MuiChip: {
      root: {
        backgroundColor: 'rgba(0,0,0,0.075)',
      },
    },
  },
};

export function createTheme(settings = {}, themeConfigsIn) {
  if (!themeConfigsIn) {
    return null;
  }

  let themeConfig = themeConfigsIn.find(
    (theme) => theme.name === settings.theme
  );

  if (!themeConfig) {
    console.warn(new Error(`The theme ${settings.theme} is not valid`));
    [themeConfig] = themeConfigsIn;
  }

  let theme = createMuiTheme(
    merge({}, baseConfig, themeConfig, { direction: settings.direction })
  );

  if (settings.responsiveFontSizes) {
    theme = responsiveFontSizes(theme);
  }

  return theme;
}