/** eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { getHostSite } from 'src/services/siteConfigService';
import { SITE } from 'src/sites';
import { getCurrentLanguage } from 'src/utils/language';
import { LANGUAGE_CODES } from 'src/constants/languageCodes';
import useAgents from 'src/hooks/useAgents';
import { TURO_US_AGENTS, TURO_CANADA_AGENTS } from 'src/constants/turo';

export const CountryContext = React.createContext({});

export default function CountryProvider({ children }) {
  const { t } = useTranslation();
  const hostSite = getHostSite();
  const { 
    selectedAgentCodesStringified, 
    turoAgentCodesAreSelected,
  } = useAgents();

  // Get list of country options based on the host site
  const getCountryOptions = () => {
    let countryOptions = [
      { 
        value: 'United States', 
        label: t('common.location.label.unitedStates'), 
        language: LANGUAGE_CODES.enUS,
      },
    ]; 
    // Add country options conditionally here
    if (hostSite === SITE.BIRCHWOOD) 
      countryOptions = [
        ...countryOptions,
        { 
          value: 'Canada', 
          label: t('common.location.label.canada'), 
          language: LANGUAGE_CODES.fr,
        },
      ];
      
    if (hostSite === SITE.NSD_DEMO)
      countryOptions = [
        ...countryOptions,
        { 
          value: 'Canada', 
          label: t('common.location.label.canada'), 
          language: LANGUAGE_CODES.fr,
        },
        { 
          value: 'Mexico', 
          label: t('common.location.label.mexico'), 
          language: LANGUAGE_CODES.es,
        },
      ];

    if (hostSite === SITE.TURO) {
      countryOptions = [
        ...countryOptions,
        { 
          value: 'Canada', 
          label: t('common.location.label.canada'), 
          language: LANGUAGE_CODES.fr,
        },
      ];
    }

    return countryOptions;
  };

  // eslint-disable-next-line
  const countries = useMemo(() => getCountryOptions(), [t]);

  // This is so the selected county automatically changes
  // based on the site language for the reporting modules
  const countriesMatchingSiteLanguage = countries.filter(
    (country) => country.language === getCurrentLanguage()
  );

  const getCountryThatMatchesAgent = () => {
    /**
     * This is to ensure that the starting country always 
     * matches the selected agent for Turo's portal. This is
     * especially needed when agents are cached. 
     * 
     * (e.g. When the page is reloaded and the cache shows that 
     * the Canada agent is selected, we need the country to be set 
     * to Canada). 
     */
    if (!selectedAgentCodesStringified) return countries.find((x) => x.value === 'United States'); // United States

    if (turoAgentCodesAreSelected(TURO_CANADA_AGENTS) && 
        !turoAgentCodesAreSelected(TURO_US_AGENTS)) {
          return countries.find((x) => x.value === 'Canada'); // Canada
    }

    return countries.find((x) => x.value === 'United States'); // United States
  };

  const getInitialCountry = () => {
    if (hostSite === SITE.TURO) {
      return getCountryThatMatchesAgent();
    }
    else if (hostSite === SITE.BIRCHWOOD) {
      return countries.find((x) => x.value === 'Canada'); // Canada
    }
    return countriesMatchingSiteLanguage[0] ?? countries.find((x) => x.value === 'United States'); // United States
  };

  // Default to the USA if no matching counties are found
  const initialCountryState = getInitialCountry();
  const [country, _setCountry] = useState(initialCountryState);

  const handleSetCountry = useCallback(
    (country) => _setCountry(countries.find((x) => x.value === country)),
    [countries]
  );

  return (
    <CountryContext.Provider
      value={{ 
        setCountry: handleSetCountry, 
        country, 
        countries, 
      }}
    >
      {children}
    </CountryContext.Provider>
  );
}

CountryProvider.propTypes = {
  children: PropTypes.elementType,
};
