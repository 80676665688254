export default {
  totalCalls: 'Total de Llamadas',
  dispatchCalls: 'Répartir les appels',
  dispatched: 'Envoyé',
  notDispatched: 'Non Expédié',
  callsByPercent: 'Appels par pourcentage',
  cash: 'Espèces',
  goneOnArrival: 'Parti à l\'Arrivée',
  cancelled: 'Annulé',
  callMessaging: 'Messagerie d\'Appel',
  callRecording: 'Enregistrement d\'appel',
  owner: 'Propriétaire',
  year: 'Année',
  make: 'Faire',
  model: 'Modèle',
  vin_abbreviation: 'NIV',
  color: 'Couleur',
  serviceType: 'Taper de service',
  breakdownReason: 'Raison de la Panne',
  noDispatchesFound: 'Aucune Expédition Trouvée',
  noImpoundsFound: 'Aucune mise en fourrière trouvée',
  'noDispatches.message':
    'Veuillez essayer une autre plage horaire ou sélectionner d\'autres agents.',
  new: 'Nouveau',
  accepted: 'Accepté',
  inRoute: 'En route',
  onScene: 'Sur les lieux',
  towInProgress: 'Remorquage en cours',
  destinationArrival: 'Arrivée à destination',
  complete: 'Complet',
  digitalDispatch: 'Envoi Numérique',
};