export default {
  'claim': 'Afirmar',
  'noClaimsFound': 'No se encontraron reclamos',
  'noClaims.message': 'Intente con otro rango de tiempo o seleccione diferentes agentes.',
  'pleaseSelectDifferentStatusesAdjustersUnderwritersLendersOrProducts': 'Seleccione diferentes estados, ajustadores, aseguradores, prestamistas o productos',
  'totalClaims': 'Total de Reclamaciones',
  'processed': 'Procesado',
  'denied': 'Negado',
  'paid': 'Pagado',
  'open': 'Abierto',
  'closed': 'Cerrado',
  cwop_abbreviation: 'CWOP',
  'processedClaimsByPercentage': 'Reclamaciones procesadas por porcentaje',
  'byPercentage': 'por porcentaje',
  'pleaseCheckBackLater': 'Por favor, vuelva más tarde',
  'noProcessedClaims': 'Sin Reclamos Procesados',
  'investigation': 'Investigación',
  'paymentReview': 'Revisión de Pagos',
  'denial': 'Negación',
  'customerCommunication': 'Comunicación con el Cliente',
  'other': 'Otro',
  'searchClaims': 'Reclamos de búsqueda',
  'claimAmount': 'Reclamar Cantidad',
  'claimType': 'Tipo de Reclamo',
  'dateOfLoss': 'Fecha de la Pérdida',
  'isDisbursed': 'Se Desembolsa',
  'created': 'Creado',
  'createdAt': 'Creado en',
  'claimant': 'Demandante',
  'claimNumber_hash': 'Afirmar #',
  'type': 'Tipo',
  'requested': 'Solicitado',
  'approved': 'Aprobado',
  'status': 'Estado',
  'disposition': 'Disposición',
  'mobilePhone': 'Teléfono Móvil',
  'shipToPhone': 'Enviar al Teléfono',
  'isRegistered': 'Está Registrado',
  'optInEmail': 'Correo Electrónico de Suscripción',
  'optInSMS': 'SMS de Suscripción',
  'policy': 'Política',
  'agentNumber_hash': 'Agente #',
  'prNumber_hash': 'PR #',
  'transactionDate': 'Fecha de Transacción',
  'datePaid': 'Fecha de Pago',
  'effectiveDate': 'Fecha Efectiva',
  'expirationDate': 'Fecha de Caducidad',
  'term': 'Término',
  'tireRackInvoice': 'Factura del Tire Rack',
  'invoiceDate': 'Fecha de la Factura',
  'invoiceNumber': 'Factura #',
  'totalPrice': 'Precio Total',
  'roadHazardId': 'Identificación de Peligro en la Carretera',
  'vehicle': 'Vehículo',
  'vehicleYear': 'Año del Vehículo',
  'vehicleMake': 'Marca del Vehículo',
  'vehicleModel': 'Modelo de Vehículo',
  'tire': 'Neumático',
  'tireMake': 'Marca de Neumático',
  'tireModel': 'Modelo de Neumático',
  'diameter': 'Diámetro',
  'consumerPrice': 'Precio al Consumidor',
  'tireRackPrice': 'Precio de Tire Rack',
  'quantity': 'Cantidad',
  'claimLog': 'Registro de Reclamaciones',
  'createdBy': 'Creado Por',
  'updatedAt': 'Actualizado en',
  'updatedBy': 'Actualizado Por',
  'reserve': 'Reserva',
  'remain': 'Permanecer',
  'remaining': 'Restante',
  'causeOfLoss': 'Causa de la pérdida',
  'treatyInfo': 'Información del tratado',
  'member': 'Miembro',
  'planName': 'Nombre del plan',
  'plan': 'Plan',
  'code': 'Código',
  'cert': 'Cert',
  'fax': 'Fax',
  'entryDate': 'Fecha de entrada',
  'lossDate': 'Fecha de pérdida',
  'closedDate': 'Fecha de cierre',
  'deniedDate': 'Fecha denegada',
};