import {
  ApolloClient,
  ApolloLink,
  createHttpLink,
  InMemoryCache,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { CamelCase } from '@nsd/fe';

// convert cookie string to object
const parseCookie = (cookie) =>
  cookie.split(';').reduce((res, c) => {
    const [key, val] = c.trim().split('=').map(decodeURIComponent);
    try {
      return Object.assign(res, { [key]: JSON.parse(val) });
    } catch (e) {
      return Object.assign(res, { [key]: val });
    }
  }, {});

const getAccessToken = () => {
  const { 'nsd.token': token } = parseCookie(document.cookie);
  if (!token) {
    return null;
  }

  return token;
};

class GraphQLClient {
  constructor(uri) {
    this.createClient(uri);
  }

  createClient(uri) {
    const authLink = setContext((_, { headers }) => {
      // get the authentication token from local storage if it exists
      const token = getAccessToken(); // localStorage.getItem('accessToken');
      // return the headers to the context so httpLink can read them
      return {
        headers: {
          ...headers,
          'Ocp-Apim-Subscription-Key': process.env.REACT_APP_NSD_API_KEY,
          authorization: token ? `Bearer ${token}` : '',
        },
      };
    });

    const httpLink = createHttpLink({
      uri: uri || process.env.REACT_APP_PROVIDER_API + '/graphql',
      // credentials: 'include'
    });

    const caseLink = new ApolloLink((operation, forward) => {
      return forward(operation).map(data => {
        return CamelCase(data);
      });
    });

    const errorLink = onError(
      ({ graphQLErrors, networkError, operation, response }) => {
        console.log('errorLink: operation', operation);
        if (graphQLErrors) {
          graphQLErrors.forEach(err => {
            const { message, locations, path } = err;
            switch (err.extensions.code) {
              case 'UNAUTHENTICATED':
                response.data[path] = {
                  success: false,
                  token: null,
                  user: null,
                  error: 'Invalid user name or password',
                };
                response.errors = null;
                if (this.onLogout) {
                  this.onLogout();
                }
                break;
              default:
                console.log('response', response);
                console.log('message', message);
                console.log('locations', locations);
                console.log('path', path);
                break;
            }
          });
        }

        if (networkError) {
          console.log(networkError);
          console.log(`[Network error]: ${networkError}`);
        }
      }
    );

    this.cache = new InMemoryCache({
      addTypename: false,
    });

    this.client = new ApolloClient({
      link: errorLink
        .concat(authLink)
        .concat(caseLink)
        .concat(httpLink),
      cache: this.cache,
    });
  }
}

export default GraphQLClient;
