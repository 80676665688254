import React from 'react';
import {
  Box,
  CardMedia,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CarSolidIcon from 'src/icons/CarSolid';
import coverImage from 'src/images/nsd-bg.png';
import { useTranslation, Trans } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  media: {
    borderTopRightRadius: 4,
    borderBottomRightRadius: 4,
    padding: theme.spacing(3),
    color: theme.palette.common.white,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  carIcon: {
    width: 40,
    height: 40,
  },
}));

function LoginMediaCard() {
  const { t } = useTranslation();

  const classes = useStyles();

  return (
    <CardMedia className={classes.media} image={coverImage} title="Cover">
      <Typography color="inherit" variant="subtitle1">
        {
          // "Login to view call details, analytics, live maps and more."
          t('login.description')
        }
      </Typography>
      <Box alignItems="center" display="flex" mt={3}>
        <CarSolidIcon className={classes.carIcon} />
        <Box ml={3}>
          <Typography color="inherit" variant="body1">
            {
              // "NSD Customer Portal"
              t('software.name')
            }
          </Typography>
          <Typography color="inherit" variant="body2">
            <Trans
              i18nKey="software.version"
              values={{ version: process.env.REACT_APP_VERSION }}
              defaults="Version {{version}}"
            />
          </Typography>
        </Box>
      </Box>
    </CardMedia>
  );
}

export default LoginMediaCard;
