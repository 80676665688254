import translations from './ClaimsView';

export default {
  'amount': 'Amount',
  'billing': 'Facturation',
  'billingMonth': 'Mois de Facturation',
  'billingPeriod': 'Période de Facturation',
  'invoices': 'Factures d\'Achat',
  'invoiceNumber': 'Facture d\'Achat #',
  'status': translations.status,
  'document': 'Document',
  'allDocuments': 'Tous les Documents',
  'cancellationStatement': 'Déclaration d\'Annulation',
  'claimInvoice': 'Facture de Réclamation',
  'printedCancellationCheck': 'Chèque d\'Annulation Imprimé',
};