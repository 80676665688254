import { GraphQLClient } from 'src/components/GraphQL';

class BaseService {
  constructor(uri) {
    const graphQL = new GraphQLClient(uri);
    this.client = graphQL.client;
  }

  /**
   * Clear the entire MemoryCache of responses
   *  @returns {Promise<any[]>}
   */
  async clearStore () {
   await this.client.clearStore();
  }
}

export default BaseService;
