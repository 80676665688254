import { getCurrentLanguage } from './language';

/**
 * Provides layout adjustments for non-English locales.
 * @param {number} defaultWidth 
 * @param {number} adjustedWidth 
 * @returns {number}
 */
export const adjustWidthForLocale = (defaultWidth, adjustedWidth) => {
  switch (getCurrentLanguage()) {
    case 'es':
    case 'fr':
    case 'fr-CA':
      return adjustedWidth;
    default:
      return defaultWidth;
  }
};