export default {
  'claim': 'Réclamer',
  'noClaimsFound': 'Aucune réclamation trouvée',
  'noClaims.message': 'Veuillez essayer une autre plage horaire ou sélectionner d\'autres agents.',
  'pleaseSelectDifferentStatusesAdjustersUnderwritersLendersOrProducts': 'Veuillez sélectionner différents statuts, experts en sinistres, souscripteurs, prêteurs ou produits',
  'totalClaims': 'Total des Réclamations',
  'processed': 'Traité',
  'denied': 'Refusé',
  'paid': 'Payé',
  'open': 'Ouvert',
  'closed': 'Fermée',
  cwop_abbreviation: 'CWOP',
  'processedClaimsByPercentage': 'Réclamations Traitées par Pourcentage',
  'byPercentage': 'par Pourcentage',
  'pleaseCheckBackLater': 'Veuillez vérifier plus tard',
  'noProcessedClaims': 'Aucune réclamation traitée',
  'investigation': 'Enquête',
  'paymentReview': 'Récapitulatif du Paiement',
  'denial': 'Le Déni',
  'customerCommunication': 'Communication Client',
  'other': 'Autre',
  'searchClaims': 'Recherche de Revendications',
  'claimAmount': 'Montant de la Réclamation',
  'claimType': 'Type de Réclamation',
  'dateOfLoss': 'Date de la Perte',
  'isDisbursed': 'Est Décaissé',
  'created': 'Créé',
  'createdAt': 'Créé A',
  'claimant': 'Demandeur',
  'claimNumber_hash': 'Réclamer #',
  'type': 'Taper',
  'requested': 'Demandé',
  'approved': 'Approuvé',
  'status': 'Statut',
  'disposition': 'Disposition',
  'mobilePhone': 'Téléphone Portable',
  'shipToPhone': 'Expédier au Téléphone',
  'isRegistered': 'Est Enregistré',
  'optInEmail': 'E-mail d\'Inscription',
  'optInSMS': 'SMS d\'Inscription',
  'policy': 'Politique',
  'agentNumber_hash': 'Agent #',
  'prNumber_hash': 'PR #',
  'transactionDate': 'Date de la Transaction',
  'datePaid': 'Date de Paiement',
  'effectiveDate': 'Date Effective',
  'expirationDate': 'Date d\'Expiration',
  'term': 'Terme',
  'tireRackInvoice': 'Facture de Tire Rack',
  'invoiceDate': 'Date de la Facture',
  'invoiceNumber': 'Facture d\'Achat #',
  'totalPrice': 'Prix Total',
  'roadHazardId': 'Identifiant de Danger Routier',
  'vehicle': 'Véhicule',
  'vehicleYear': 'Année du Véhicule',
  'vehicleMake': 'Marque du Véhicule',
  'vehicleModel': 'Modèle de Véhicule',
  'tire': 'Pneu',
  'tireMake': 'Marque de Pneu',
  'tireModel': 'Modèle de Pneu',
  'diameter': 'Diamètre',
  'consumerPrice': 'Prix Consommateur',
  'tireRackPrice': 'Prix du Tire Rack',
  'quantity': 'Quantité',
  'claimLog': 'Journal des Réclamations',
  'createdBy': 'Créé Par',
  'updatedAt': 'Mis à jour à',
  'updatedBy': 'Mis à Jour Par',
  'reserve': 'Réserve',
  'remain': 'Rester',
  'remaining': 'Restant',
  'causeOfLoss': 'Cause de la perte',
  'treatyInfo': 'Infos sur le traité',
  'member': 'Membre',
  'planName': 'Nom du régime',
  'plan': 'Régime',
  'code': 'Code',
  'cert': 'Cert',
  'fax': 'Fax',
  'entryDate': 'Date d\'entrée',
  'lossDate': 'Date de perte',
  'closedDate': 'Date de fermeture',
  'deniedDate': 'Date de refus',
};