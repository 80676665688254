import React, { useState, useEffect } from 'react';
import { NavLink as RouterLink, useLocation } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Button, Collapse, ListItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { TRANSLATION } from 'src/constants/translationKey';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  item: {
    display: 'block',
    paddingTop: 0,
    paddingBottom: 0,
  },
  itemLeaf: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0,
  },
  button: {
    color: theme.palette.text.secondary,
    padding: '10px 8px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
  },
  buttonLeaf: {
    color: theme.palette.text.secondary,
    padding: '10px 8px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
    fontWeight: theme.typography.fontWeightRegular,
  },
  icon: {
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1),
  },
  title: {
    marginRight: 'auto',
  },
  active: {
    color: theme.palette.activeLink?.main ?? theme.palette.secondary.main,
    '& $title': {
      fontWeight: theme.typography.fontWeightBold,
      borderBottom: `2px solid ${(
        theme.palette.activeLink?.main ?? theme.palette.secondary.main
      )}`,
    },
    '& $icon': {
      color: theme.palette.activeLink?.main ?? theme.palette.secondary.main,
    },
  },
}));

/**
 * Nav item to be used exclusively within the Navbar component
 * to generate a list of nav links; depth is used to determine
 * how much to indent nested nav items
 *
 * module can also be destructured if needed...
 */
function NavItem({
  title,
  href,
  depth,
  children,
  icon: Icon,
  className,
  open: openProp,
  info: Info,
  excludedPaths = null,
  ...rest
}) {
  const { t } = useTranslation(TRANSLATION);
  const classes = useStyles();
  const location = useLocation();
  const [open, setOpen] = useState(openProp);
  const [isRouteVisible, setIsRouteVisible] = useState(true);
  const handleToggle = () => setOpen((prevOpen) => !prevOpen);
  let paddingLeft = 8;
  if (depth > 0) paddingLeft = 32 + 8 * depth;
  const style = { paddingLeft };

  /* 
    FIX: This method of hiding navItems means collapsable parent
    NavItems can be left empty (children hidden) 
    but still have the arrow rendered.
  */
  useEffect(() => {
    if (excludedPaths && excludedPaths?.length >= 0) {
      excludedPaths && excludedPaths.forEach((path) => {
        if (path === location.pathname) setIsRouteVisible(false);
      });
    }
  }, [location, excludedPaths]);

  if (!isRouteVisible) return null;
  if (children) {
    return (
      <ListItem
        className={clsx(classes.item, className)}
        disableGutters
        key={title}
        {...rest}
      >
        <Button className={classes.button} onClick={handleToggle} style={style}>
          {Icon && <Icon className={classes.icon} size="20" />}
          <span className={classes.title}>{t(title)}</span>
          {open ? (
            <ExpandLessIcon size="small" color="inherit" />
          ) : (
            <ExpandMoreIcon size="small" color="inherit" />
          )}
        </Button>
        <Collapse in={open}>{children}</Collapse>
      </ListItem>
    );
  }

  return (
    <ListItem
      className={clsx(classes.itemLeaf, className)}
      disableGutters
      key={title}
      {...rest}
    >
      <Button
        activeClassName={classes.active}
        className={clsx(classes.buttonLeaf, `depth-${depth}`)}
        component={RouterLink}
        exact
        style={style}
        to={href}
      >
        {Icon && <Icon className={classes.icon} size="20" />}
        <span className={classes.title}>{t(title)}</span>
        {Info && <Info className={classes.info} />}
      </Button>
    </ListItem>
  );
}

NavItem.propTypes = {
  /**
   * The nested NavItems within it
   * Should be automatically
   * generated by reduceChildRoutes (in NavBar.js)
   */
  children: PropTypes.node,
  /**
   * Class to apply to the outermost element container
   */
  className: PropTypes.string,
  /**
   * Used to determine indenetation levels (padding-left)
   * if the current NavItem is nested. Should be automatically
   * generated by reduceChildRoutes (in NavBar.js)
   */
  depth: PropTypes.number.isRequired,
  /**
   * Link to another page when NavItem is clicked
   */
  href: PropTypes.string,
  /**
   * An icon component (as a Object) to display next to the
   * NavBar item
   */
  icon: PropTypes.object,
  /**
   * A React component that can be rendered to display
   * additional information
   */
  info: PropTypes.object,
  /**
   * Weather or not to display the menu item;
   * Should be automatically generated
   * by reduceChildRoutes (in NavBar.js)
   */
  open: PropTypes.bool,
  /**
   * Name of the rendered NavItem
   */
  title: PropTypes.string.isRequired,
  /**
   * Non - relative link to to the specified NavItem
   * (e.g. When the href is "/test" , externalLink should be
   * https://someTopLevelDomain.com/test, a full path)
   *
   * NOTE: Currently not useed
   */
  externalLink: PropTypes.string,

  /**  An array of relative links
   *  ex: ['/', '/admin/path1'] that you don't want the
   *  current nav item to be rendered on.
   */
  excludedPaths: PropTypes.arrayOf(PropTypes.string),
};

NavItem.defaultProps = {
  children: null,
  className: '',
  depth: 0,
  href: null,
  icon: null,
  info: null,
  open: true,
  title: 'Item Name',
  externalLink: null,
  excludedPaths: null,
};

export default NavItem;
