export default {
  'confieChargedClients': 'Clientes de Confie Cobrar',
  'nsdChargedConfie': 'Confie Cobrada de NSD',
  'applicationsReceivedByNSD': 'Solicitudes recibidas por NSD',
  'nsdChargedConfiePerApplicationAverage': 'Confianza Cobrada por NSD por Aplicación (Promedio)',
  'confieChargedClientsPerApplicationAverage': 'Confie Cobrar a los Clientes por Aplicación (Promedio)',
  'confieProfit': 'Confie Beneficio',
  'profit': 'Beneficio',
  'totalProfit': 'Beneficio Total',
  'totalProfitByState': 'Beneficio Total por Estado',
  'searchProducts': 'Buscar productos', 
  'office': 'Oficina',
  'district': 'Distrito',
  'product': 'Producto',
};