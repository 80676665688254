import React from 'react';
import clsx from 'clsx';
import { Typography, Toolbar } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import dayjs from 'dayjs';
import { Logo, THEMES } from '@nsd/fe';
import { NSD_STATIC_URL } from 'src/utils/blobStorage';
import { useTranslation } from 'react-i18next';
import { TRANSLATION } from 'src/constants/translationKey';

/**
 * @param {import('@material-ui/core/styles').Theme} theme 
 * @returns {string}
 */
const getToolbarBackgroundColor = (theme) => {
  return theme.palette.bottomBar?.main ?? theme.palette.grey[300];
}

/**
 * @param {import('@material-ui/core/styles').Theme} theme 
 * @returns {string}
 */
const getToolbarTextColor = (theme) => {
  return theme.palette.bottomBar?.text ?? theme.palette.text.primary;
}

const useStyles = makeStyles((theme) => ({
  toolbar: {
    ...(theme.name === THEMES.ONE_DARK
      ? {
          backgroundColor: theme.palette.background.default,
        }
      : {
          backgroundColor: getToolbarBackgroundColor(theme),
        }
      ),
    boxShadow: theme.shadows[20],
    minHeight: 24,
    width: '100%',
    position: 'fixed',
    bottom: 0,
    zIndex: 1201,
    justifyContent: 'space-between',
    '@media (max-width: 568px)': {
      flexDirection: 'column',
    },
  },
  text: {
    color: getToolbarTextColor(theme),
    fontSize: '0.825rem',
    fontWeight: 500,
    lineHeight: 1.9,
  },
  poweredBy: {
    display: 'flex',
    alignSelf: 'center',
  },
  poweredByText: {
    marginRight: 5,
  },
  logo: {
    alignSelf: 'center',
  },
}));

function BottomBar() {
  const { t } = useTranslation(TRANSLATION, {keyPrefix: 'BottomBar'});

  const classes = useStyles();

  return (
    <Toolbar className={classes.toolbar}>
      <span className={classes.poweredBy}>
        <Typography
          className={clsx(
            classes.poweredByText, 
            classes.text
          )}
        >
          {t('software.poweredBy')}
        </Typography>
        <Logo 
          className={classes.logo}
          image={`${NSD_STATIC_URL}/assets/nsd.png`} 
          width={54}
          height={15}
        />
      </span>
      <Typography className={classes.text}>
        {t('copyright')} © {dayjs().format('YYYY')} NSD Group, LLC.{' '}
        {t('allRightsReserved')}.
      </Typography>
    </Toolbar>
  );
}

export default BottomBar;
